<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <NuxtImg v-if="variant === variants.v1" :src="vars.backgroundImage" class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover" />
    <div class="tw-absolute tw-inset-0 tw-bg-[--c-primary] tw-opacity-80" v-if="variant === variants.v1"></div>
    <div class="tw-z-10 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-10">
      <h2 v-if="variant === variants.v1" class="tw-text-center tw-text-white">
        {{ vars.titleText }}
      </h2>
      <div class="tw-flex tw-w-full tw-flex-col tw-gap-6 lg:tw-gap-10">
        <div v-if="variant === variants.GoogleReviews" v-html="vars.titleHtml" class="tw-flex tw-flex-wrap tw-gap-x-4 tw-text-black"></div>
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="false"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :breakpoints="computedBreakpoints"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
          class="tw-w-full lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(review, index) in reviews" :key="`testimonials-review-${index}`" class="!tw-h-auto">
            <div
              class="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-3 tw-rounded tw-bg-white tw-p-10 tw-text-start tw-text-black lg:tw-max-h-[320px]"
              :class="{
                'left-border': variant === variants.GoogleReviews,
              }"
            >
              <template v-if="variant === variants.v1">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <svg
                      v-for="n in review.rating"
                      :key="`testimonials-star-${n}`"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                  <p v-if="variant === variants.v1">
                    {{ review.date }}
                  </p>
                </div>
                <h4 class="tw-line-clamp-1">{{ review.title }}</h4>
                <p class="tw-max-h-48 tw-overflow-y-auto lg:tw-max-h-40">“{{ review.description }}"</p>
              </template>
              <template v-if="variant === variants.GoogleReviews">
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <svg
                      v-for="n in review.evaluation_value"
                      :key="`testimonials-star-${n}`"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                        fill="var(--c-primary)"
                      />
                    </svg>
                  </div>
                </div>
                <h4 class="tw-line-clamp-1">{{ review.person_name }}</h4>
                <p class="tw-max-h-48 tw-overflow-y-auto lg:tw-max-h-40">“{{ review.content }}"</p>
              </template>
            </div>
          </SwiperSlide>
          <div class="controls">
            <div
              data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
              class="slide-left-arrow w-slider-arrow-left"
              :isDisabled="!canPaginateBack"
              @click="() => swiper.slidePrev()"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
                  fill="#6EADAB"
                />
              </svg>
              <div class="slide-circle slider-circle-transform"></div>
            </div>
            <div
              data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
              class="slide-right-arrow w-slider-arrow-right"
              :isDisabled="!canPaginateNext"
              @click="() => swiper.slideNext()"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
                  fill="#6EADAB"
                />
              </svg>
              <div class="slide-circle right"></div>
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      reviews: null,
      variants: {
        v1: 'v1',
        GoogleReviews: 'GoogleReviews',
      },
    };
  },

  created() {
    const parsedReviews = this.parseFormV2(this.vars.googleReviews);
    this.reviews = parsedReviews?.reviews;
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },

  computed: {
    variant() {
      return useVariant(this.component);
    },

    reviews() {
      return this.groupedVariables.reviews;
    },

    computedBreakpoints() {
      const baseBreakpoints = {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      };

      if (this.variants.variant === this.variants.GoogleReviews) {
        baseBreakpoints[1336] = {
          slidesPerView: 2,
          spaceBetween: 24,
        };
      } else if (this.variants.variant === this.variants.v1) {
        baseBreakpoints[1336] = {
          slidesPerView: 3,
          spaceBetween: 24,
        };
      }

      return baseBreakpoints;
    },
  },

  mounted() {
    this.onSlideChange();
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-primary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
